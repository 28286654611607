import { Row, Col } from 'reactstrap';
import CustomCard from '../../assets/images/card.webp';
export default function PromoCode({
  promoCode,
  setPromoCode,
  handlePromoCode,
  t,
  hidden,
}) {
  return (
    <>
      <Row className='ps-3 pe-3 align-items-end'>
        <Col
          md={6}
          className='mb-2'
        >
          <label
            htmlFor='promo-code'
            className='form-label'
          >
            <strong>{t('promocode.fields.title')}</strong>
          </label>
          <input
            type='text'
            id='promo-code'
            className='form-control w-100'
            value={promoCode}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value?.toUpperCase();
              setPromoCode(newValue);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-2'
        >
          <button
            className='w-full flex items-center justify-center gap-3 mt-6 px-6 py-2.5 bg-gradient-to-r from-teal-900 to-teal-600 text-white font-bold rounded-lg transition-all duration-300 hover:from-gray-800 hover:to-teal-300'
            onClick={handlePromoCode}
          >
            {t('promocode.fields.apply')}
          </button>
        </Col>
        <Col
          md={6}
          className='mb-2'
        >
          <span
            className='text-sm text-red-600'
            hidden={hidden}
          >
            Cupom inválido ou expirado.
          </span>
        </Col>
      </Row>
    </>
  );
}
