import { Row, Col } from 'reactstrap';
import { brlMask } from '../../helpers/masks';
import { LoadingImg } from '../../helpers/images';
import CustomCard from '../../assets/images/card.webp';

export function Tickets({
  tickets,
  handleAdd,
  handleRemove,
  handleBuy,
  t,
  disabled,
}) {
  if (tickets.length === 0) {
    return (
      <>
        <Row>
          <Col className='d-flex flex-row justify-content-center align-items-center mb-3'>
            <img
              alt='...'
              src={LoadingImg}
              className='w-25'
            />
          </Col>
        </Row>
      </>
    );
  }
  return (
    <>
      {/* <Row>
        <Col className='mb-3'> */}
      <div class='font-[sans-serif] mx-auto lg:max-w-6xl max-w-lg md:max-w-full'>
        <div
          className={`grid grid-cols-1 md:grid-cols-2 ${
            tickets.length === 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-3'
          } gap-6 `}
        >
          {tickets.map((ticket, index) => (
            <>
              <div class='bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full py-6 max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4 cursor-pointer hover:scale-[1.03] transition-all relative overflow-hidden'>
                <div class='flex items-center align-middle text-center gap-2 px-6 min-h-[60px]'>
                  <h4 class='text-xl text-gray-800 font-bold flex-1'>
                    {ticket.description}
                  </h4>
                </div>

                <div class='min-h-[300px]'>
                  <img
                    src={ticket?.imageUrl || CustomCard}
                    class='w-full my-6'
                  />
                </div>

                <div class='px-6'>
                  <p class='text-sm text-gray-600 leading-relaxed'>
                    {ticket.description2}
                  </p>

                  <div class='mt-8  items-center flex flex-row align-top gap-3'>
                    <h3 class='text-xl text-gray-800 font-bold flex-1'>
                      {brlMask(ticket.price)}
                    </h3>
                    <div className='flex flex-row items-center'>
                      <button
                        className='px-3 py-2 bg-gradient-to-r from-teal-900 to-teal-600 text-white font-bold rounded-lg transition-all duration-300 hover:from-gray-800 hover:to-teal-300'
                        onClick={(e) => handleRemove(e, index)}
                      >
                        <i className='bi bi-dash'></i>
                      </button>
                      <span className='px-3 py-2 flex text-center text- justify-center align-middle items-center px-3 py-1.5  text-gray-800 w-full font-bold'>
                        {ticket.count}
                      </span>
                      <button
                        disabled={disabled}
                        className={`px-3 py-2 text-white font-bold rounded-lg transition-all duration-300 
    ${
      disabled
        ? 'bg-gray-500 cursor-not-allowed'
        : 'bg-gradient-to-r from-teal-900 to-teal-600 hover:from-gray-800 hover:to-teal-300'
    }`}
                        onClick={(e) => handleAdd(e, index)}
                      >
                        <i className='bi bi-plus'></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
        <div className='flex flex-row'>
          <button
            id='tickets-buy'
            // className='w-full flex items-center justify-center gap-3 mt-6 px-6 py-3 bg-gradient-to-r from-teal-900 to-teal-600 text-white font-bold rounded-xl text-xl transition-all duration-300 hover:from-green-400 hover:to-blue-400'
            className='w-full mt-6 px-4 py-6 text-2xl bg-gradient-to-r from-black to-teal-500 text-white font-bold rounded-lg transition-all duration-300 hover:from-gray-800 hover:to-teal-300'
            onClick={handleBuy}
          >
            <i className='bi bi-cart-check me-2'></i>
            Avançar
          </button>
        </div>
      </div>
    </>
  );
}
