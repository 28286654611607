export default function Steps({
  handlePrev,
  handleNext,
  textPrev,
  textNext,
  disableNext = false,
  idStr = 'btn-next',
}) {
  return (
    <>
      <section className='d-flex flex-row justify-content-end mt-3 gap-1'>
        <button
          type='button'
          className='w-full flex items-center justify-center gap-3 mt-6 px-6 py-3 bg-gradient-to-r from-teal-900 to-teal-600 text-white font-bold rounded-lg transition-all duration-300 hover:from-gray-800 hover:to-teal-300'
          onClick={handlePrev}
        >
          {textPrev}
        </button>
        <button
          type='button'
          className='w-full flex items-center justify-center gap-3 mt-6 px-6 py-3 bg-gradient-to-r from-teal-900 to-teal-600 text-white font-bold rounded-lg transition-all duration-300 hover:from-gray-800 hover:to-teal-300'
          onClick={handleNext}
          disabled={disableNext}
          id={idStr}
        >
          {textNext}
        </button>
      </section>
    </>
  );
}
