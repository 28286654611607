import { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../components/header';
import { getTickets2 } from '../../services/product';
import { Information } from '../../components/information';
import { useNavigate } from 'react-router-dom';
import { TransactionContext } from '../../contexts/TransactionContext';
import { useTranslation } from 'react-i18next';
import { Tickets } from '../../components/tickets';
import Event from '../../components/event';
import { EVENTID } from '../../helpers/constants';
import Banner from '../../assets/images/banner.webp';
import Banner1920 from '../../assets/images/banner1920.webp';
import { useError } from '../../hooks/useError';

export const Cart = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { setCart, firstStep, email, loadedTickets } =
    useContext(TransactionContext);
  const [tickets, setTickets] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const { goToFieldWithError } = useError();
  const updateTicketCount = (value, index) =>
    setTickets((prevState) => {
      const newState = [...prevState];
      const currentCount = newState[index].count;
      const newCount = currentCount + value;
      if (newState[index] === undefined || newCount > 1 || newCount < 0) {
        return newState;
      }
      newState[index] = { ...newState[index], count: newCount };
      return newState;
    });

  const handleAddTicket = (e, index) => {
    const ticketOnCart = tickets.filter((ticket) => ticket.count > 0);

    if (ticketOnCart.length === 0) {
      updateTicketCount(1, index);
      setDisabled(true);
      goToFieldWithError('tickets-buy');
    }
  };

  const handleRemoveTicket = (e, index) => {
    const ticketOnCart = tickets[index].count === 1;
    if (ticketOnCart) {
      updateTicketCount(-1, index);
      setDisabled(false);
    }
  };

  const handleBuy = (e) => {
    e.preventDefault();
    const ticketToBuy = tickets.filter((ticket) => ticket.count > 0);
    if (ticketToBuy.length > 0) {
      setCart(ticketToBuy);
      navigate('/pagamento');
    }
  };

  useEffect(() => {
    const fetchTickets = async () => {
      i18n.changeLanguage('pt');
      document.documentElement.setAttribute('lang', t('language'));
      document.title = t('title');
      const response =
        email === '' ? await getTickets2(EVENTID) : loadedTickets;
      const newTickets = response.map((ticket) => ({
        ...ticket,
        count: 0,
      }));
      setTickets(newTickets);
      firstStep();
    };
    fetchTickets();
  }, []);

  return (
    <div className=' max-h-full'>
      {/* <Header /> */}
      <div class='flex justify-center items-center'>
        <div class='flex flex-col items-center'>
          <img
            src={Banner1920}
            class='w-full h-[187px] md:w-[970px] md:h-[484px] object-fill'
            alt='Congresso Wainer'
          />
          <div class='flex flex-col  h-[187px] md:w-[970px] md:h-[484px] mt-6 ms-3 me-3'>
            {email === '' && (
              <>
                <h2 class='text-white lg:text-5xl text-3xl font-bold lg:!leading-[56px]'>
                  Onde a Ciência encontra a prática
                </h2>

                {/* <p class='text-white mt-6 text-base leading-relaxed'>
                  Com mais de 15 anos de experiência no setor de eventos
                  voltados para a psicologia, o Congresso Wainer se destaca como
                  um dos principais encontros da área. Desde nossas primeiras
                  Jornadas Acadêmicas em 2007, temos trazido grandes nomes
                  internacionais, promovendo a troca de conhecimento e a
                  atualização profissional.
                </p>

                <p class='text-white mt-6 text-base leading-relaxed'>
                  Junte-se a nós e faça parte dessa jornada de aprendizado e
                  crescimento. O Congresso Wainer é mais do que um evento, é uma
                  oportunidade de se conectar com especialistas que estão
                  mudando o cenário, expandir seus conhecimentos e explorar
                  novas abordagens na psicologia.
                </p>
                <p className='text-white mt-6 text-base leading-relaxed'>
                  Participe agora e não perca essa chance de se destacar na sua
                  carreira!
                </p> */}
              </>
            )}

            {/* <p className='text-white mt-6 text-base leading-relaxed'>
              Ingresso individual e intransferível. Apenas 1 ingresso por
              pessoa.
            </p> */}
            <Tickets
              handleAdd={handleAddTicket}
              handleRemove={handleRemoveTicket}
              handleBuy={handleBuy}
              tickets={tickets}
              t={t}
              disabled={disabled}
            />
            <div className='text-white mt-6 text-base leading-relaxed'>
              <table className='table-auto w-full mb-3 border-collapse border-spacing-4'>
                <thead>
                  <tr cla>
                    <th className='p-3 border border-slate-600 align-middle w-[200px]'>
                      Categoria
                    </th>
                    <th className='p-3 border border-slate-600 align-middle w-[400px]'>
                      Requisitos
                    </th>

                    <th className='p-3 border border-slate-600 text-left align-middle'>
                      <p className='m-0'>LOTE 1</p>
                      <p className='text-left text-xs'>31/12/2024*</p>
                    </th>
                    <th className='p-3 border border-slate-600 text-left align-middle'>
                      <p className='m-0'>LOTE 2</p>
                      <p className='text-left text-xs'>31/04/2025*</p>
                    </th>
                    <th className='p-3 border border-slate-600 text-left align-middle'>
                      <p className='m-0'>LOTE 3</p>
                      <p className='text-left text-xs'>até 10/09/2024*</p>
                    </th>
                  </tr>
                </thead>
                <tbody className='space-y-4'>
                  <tr>
                    <td className='p-3 align-middle border border-slate-600 '>
                      Profissionais e Estudantes (Psicologia, Psiquiatria e
                      Medicina)
                    </td>
                    <td className='p-3 align-middle border border-slate-600'>
                      Psicólogos, Psiquiatras e Médicos, ou estudantes
                      matriculados em cursos de Psicologia, Medicina, ou
                      residência em Psiquiatria com conclusão após 11 de
                      setembro de 2025.
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.499,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.699,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.999,00
                    </td>
                  </tr>
                  <tr>
                    <td className='p-3 border border-slate-600 align-middle'>
                      Alunos Wainer
                    </td>
                    <td className='p-3 border border-slate-600 align-middle'>
                      Participantes matriculados em cursos de formação,
                      especializações ou cursos online do Grupo Wainer com
                      conclusão prevista até 11 de março de 2026.
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.399,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.599,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.899,00
                    </td>
                  </tr>
                  <tr>
                    <td className='p-3 border border-slate-600 align-middle'>
                      Outras Áreas (Profissionais da área da saúde ou educação)
                    </td>
                    <td className='p-3 border border-slate-600 align-middle'>
                      Profissionais ou estudantes de saúde e educação, como:
                      nutricionista, fisioterapeuta, fonoaudiólogo, terapeuta
                      ocupacional, assistente social, pedagogo, enfermeiro,
                      biomédico, farmacêutico.
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.299,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.499,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.799,00
                    </td>
                  </tr>
                  <tr>
                    <td className='p-3 border border-slate-600 align-middle'>
                      Pesquisadores
                    </td>
                    <td className='p-3 border border-slate-600 align-middle'>
                      Para inscrever-se nesta categoria, é necessário submeter
                      um trabalho científico dentro dos prazos e regras
                      destinadas a esta atividade. Categoria limitada a 50
                      inscrições.
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.349,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.549,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.849,00
                    </td>
                  </tr>
                  <tr>
                    <td className='p-3 border border-slate-600 align-middle'>
                      VIP
                    </td>
                    <td className='p-3 border border-slate-600 align-middle'>
                      Qualquer categoria que deseje usufruir dos benefícios VIP
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.699,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 1.999,00
                    </td>
                    <td className='p-3 border border-slate-600 align-middle text-right'>
                      R$ 2.299,00
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3>Comprovação de Categoria:</h3>
              <p className='text-justify'>
                Os documentos comprobatórios, quando não forem anexados via
                site, devem ser enviados ao e-mail{' '}
                <a href='mailto:eventos@wainerpsicologia.com.br'>
                  eventos@wainerpsicologia.com.br
                </a>{' '}
                para análise e confirmação da inscrição no prazo máximo de 15
                dias após a inscrição, conforme relação a seguir:
              </p>
              <ul>
                <li>
                  Categoria profissional deverá constar número de registro no
                  conselho da categoria ou cópia do diploma de graduação.
                </li>
                <li>
                  Categoria estudante de graduação serão aceitos um ou mais dos
                  seguintes documentos comprobatórios: Carteirinha de estudante
                  (frente e verso) válida para o ano corrente, Declaração de
                  matrícula ou frequência, assinada e carimbada pela instituição
                  de ensino ou trabalho, referente ao semestre atual,
                  Comprovante de pagamento da mensalidade do semestre vigente da
                  instituição de ensino.
                </li>
                <li>
                  Categoria aluno Wainer não necessita de comprovação, pois a
                  mesma é feita pela instituição promotora do evento.
                </li>
                <li>
                  Categoria outras áreas a comprovação se dará pelo envio do
                  documento da carteira profissional (conselho de classe) ou
                  documento de estudante de graduação.
                </li>
              </ul>

              <p>
                Caso haja a necessidade de desistência de participação no
                evento, pedimos que a solicitação seja realizada através do
                formulário{' '}
                <a
                  href='https://docs.google.com/forms/d/e/1FAIpQLScSmWQacjUsDWsPZV6jygnt1U2HZvtIvCCit0oYI6bSmZl2fg/viewform'
                  target='_blank'
                >
                  aqui
                </a>
                , conforme os critérios descritos a seguir.
              </p>

              <h3>Prazos e Condições para Reembolso</h3>

              <p>
                Em caso de desistência formal, o reembolso dos valores pagos
                será realizado até 45 dias após o término do evento. Os
                reembolsos serão processados de acordo com os seguintes
                critérios:
              </p>

              <table>
                <thead>
                  <tr>
                    <th>Justificativa</th>
                    <th>Prazo para Solicitação</th>
                    <th>Valor a ser Reembolsado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Sem justificativa</td>
                    <td>Até 60 dias antes do Congresso</td>
                    <td>60% do valor pago</td>
                  </tr>
                  <tr>
                    <td>Sem justificativa</td>
                    <td>Até 30 dias antes do Congresso</td>
                    <td>40% do valor pago</td>
                  </tr>
                  <tr>
                    <td>Sem justificativa</td>
                    <td>Até 60 dias antes do Congresso</td>
                    <td>90% do valor pago (crédito para eventos Wainer)</td>
                  </tr>
                  <tr>
                    <td>Sem justificativa</td>
                    <td>Até 30 dias antes do Congresso</td>
                    <td>70% do valor pago (crédito para eventos Wainer)</td>
                  </tr>
                  <tr>
                    <td>Problemas de saúde</td>
                    <td>Até 5 dias antes do Congresso</td>
                    <td>80% do valor pago</td>
                  </tr>
                  <tr>
                    <td>Desistência de compra</td>
                    <td>Até 7 dias após o pagamento</td>
                    <td>100% do valor pago</td>
                  </tr>
                </tbody>
              </table>

              <h3>Procedimentos para Solicitação de Reembolso</h3>
              <ul>
                <li>
                  Sem justificativa: solicitar através do{' '}
                  <a
                    href='https://docs.google.com/forms/d/e/1FAIpQLScSmWQacjUsDWsPZV6jygnt1U2HZvtIvCCit0oYI6bSmZl2fg/viewform'
                    target='_blank'
                  >
                    formulário de reembolso
                  </a>
                  .
                </li>
                <li>
                  Problemas de saúde: solicitar através do formulário, anexando
                  cópia do atestado médico e o comprovante de pagamento.
                </li>
                <li>
                  Duplicidade de pagamento: solicitar através do formulário e
                  anexar os comprovantes de pagamento.
                </li>
                <li>
                  Desistência de compra (em até 7 dias após o pagamento):
                  solicitar através do formulário, marcando a opção "Solicitação
                  de Reembolso - Desistência de Compra" e anexando o comprovante
                  de pagamento.
                </li>
              </ul>

              <p>
                <strong>Observações:</strong> A carta de crédito concedida será
                válida para qualquer outro evento promovido pela Wainer
                Psicologia Cognitiva e terá validade de até 2 (dois) anos a
                partir da data de emissão. O prazo para solicitação dos
                reembolsos deve ser rigorosamente respeitado para que o processo
                seja validado e concluído. Permutas são permitidas até 30 dias
                antes do início do evento, sem perda de valor investido. Tal
                transação é de inteira responsabilidade do inscrito e a mudança
                de titularidade deve ser comunicada através do e-mail{' '}
                <a href='mailto:eventos@wainerpsicologia.com.br'>
                  eventos@wainerpsicologia.com.br
                </a>
                com os dados (nome completo, CPF, email e endereço) do novo
                titular.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col items-center justify-center'></div>
    </div>
  );
};
